export default {
  sidebar: {
    nav: {
      reviews: "Отзывы",
      about: "О тесте",
      models: "Модели бренда",
      benefits: "Что ты получишь",
      blog: "Блог",
    },
    startTest: "Пройти тест",
  },

  pageHome: {
    intro: {
      message:
        "Ты — бренд. \nУзнай, какие методы проявления\nподходят именно тебе",
      description: "Тест на определение модели персонального бренда",
      btn: "Подробнее",
    },

    ticker: {
      create: "НЕ копируй, а создавай",
      brand: "твой персональный бренд",
      target: 'Кому будет полезен тест',
    },

    about: {
      text: [
        "Все люди уникальны, с точки зрения личностных особенностей. Подход к построению персонального бренда у каждого свой. Нет единого решения.",
        "Ты можешь ориентироваться на людей, чей персональный бренд тебе нравится. Можешь анализировать успех, наблюдать за их действиями. Но в результате есть риск пойти ложным путем, создать фейковый образ и потерять себя.",
        "Важно оставаться верным себе. Мы создали 5 моделей персонального бренда. Каждая модель основана на особенностях нервной системы и приобретенном опыте.",
        "Пройди тест, определи свою модель и получи стратегию построения персонального бренда.",
      ],
      btn: "Подробнее",
    },

    participants: {
      title: "Кто уже прошел тест?",
    },

    chat: {
      title: "Мы на твоей стороне!",

      messages: [
        "Мне не свойственно делать то, что предлагают на курсах.\nКак быть?",
        "",
        "Агентства используют одинаковый подход для всех ",
        "Курсы по построению личного бренда  ничего не дали",
        "Какой контент создавать?",
        "Как начать работу над персональным брендом?",
      ],

      btn: "Узнать решение",
    },

    brand: {
      text: [
        "Ты наконец-то можешь создать собственный бренд, не переступая свои физиологические, психологические и социальные особенности. ",
        "Мы разработали пять моделей построения персонального бренда. Для определения твоей модели мы создали биопсихосоциальный подход к тестированию.",
        "Тест определит особенности твоей нервной системы. Для этого важно узнать показатели Резилиенса — способности организма восстанавливаться после стресса, адаптироваться к меняющимся требованиям внешней среды. В результате — построить мощную стратегию создания персонального бренда, подходящую именно тебе.",
        "Все разные: для кого-то характерна общительность и обмен энергией с социумом; кто-то ценит спокойствие и черпает вдохновение, обращаясь к своему внутреннему миру. Не ломай себя, чтобы построить персональный бренд. Ты можешь сохранить аутентичность, не жертвуя успешностью, и достичь сверх результата. ",
      ],
    },


    model: { 
      title: 'Модели персонального бренда ',
      creator: {
        name: 'Creator',
        description: 'Контент — король и Криэйторы знают, как рассказать захватывающую историю о событиях, личностях, хобби и лайфстайле.\n\nБлог, стрим, YouTube-канал, TikTok, или же компания друзей — аудитории интересно следить за каждым шагом.'
      },
      expert: { 
        name: 'Expert',
        description: 'Знание и опыт для Экспертов важнее публичности, несмотря на то, что именно они привлекают  внимание к своей индустрии.\n\nРазвитие и возможность делиться знаниями — главные приоритеты Экспертов.'
      },
      insider: {
        name: 'Insider',
        description: 'Инсайдер понимает важность  контактов. Знает, что главная ценность идеи, компании, бизнеса — это люди.\n\nЛичная рекомендация для них важнее присутствия в соцсетях. Инсайдеры активны и последовательны, легко находят неочевидные решения. '
      },
      marketer: { 
        name: "Marketer",
        description: 'Маркетеры продвигают себя с помощью своих дел. Они не обращают внимания на навязанные нормы.\n\nМаркетеры умеют взаимодействовать с людьми и готовы к проявлениям в разных каналах коммуникации. '
      },
      lister: {
        name: 'A-lister',
        description: 'А-листеров узнают, что бы они ни делали: блог, карьеру на телевидении или собственный бизнес. Их имена становятся нарицательными и не нуждаются в описании их занятий.\n\nЧто характерно для А-листеров? Активное взаимодействие в обществе и большое количество социальных контактов.'
      },
      you: {
        name: 'А кто ты?',
        btn: 'Узнать модель',
      }
    },

    propose: {
      title: 'Узнай свою модель\nперсонального бренда',
      description: 'Тест займет примерно 25 минут',
      btn: 'Начать тест',
      free: 'Бесплатно'
    },

    benefits: { 
      title: 'Ты получишь:',

      list: [
        'Описание твоей модели персонального бренда.\nМы исходим из особенностей твоей нервной системы\nи биопсихосоциальных качеств.',
        'Готовую стратегию построения персонального бренда, включая гид по контенту. Тебе больше не придется платить деньги за курсы и консультации, а главное — тратить время.',
        'Ответ, по какой модели ты действуешь и что нужно изменить и усилить, для достижения поставленных целей в кратчайшие сроки.',
        'Структурированный пошаговый план, который исключит трату лишних ресурсов для достижения результата. ',
        'Инструмент для составления личного уникального сообщения, с которым ты выйдешь в публичное пространство.  ',
      ]
    },

    faq: {
      btn: 'Пройти тест',

      questions: [
        { 
          question: 'Ты ищешь системный научный подход?',
          answer: 'Ты уже работаешь над персональным брендом и понимаешь его ценность. Но общая информация не учитывает особенности твоей личности. Тебе нужны гибкие инструменты, которые подойдут именно твоей нервной системе. Ты не хочешь тратить время и усилия на проверку  общих гипотез и рекомендаций, пересматривать часы видеороликов на YouTube.\n\nМы дадим глубинное понимание твоих личностных паттернов поведения и расскажем, как развивать то, что понравится и подойдет именно тебе. Результаты и рост не заставят себя ждать.'
        },
        { 
          question: 'Нужно начать. Но с чего?',
          answer: 'Ты понимаешь что персональный бренд необходим для карьеры, экспертизы, репутации в сфере и роста прибыли. Но каждый раз чувствуешь растерянность: “с чего начать?”. Пугает количество решений, которые нужно принять. Нет уверенности в результате. Это демотивирует и отодвигает создание персонального бренда в списке твоих ежедневных задач. Боишься неудачи, ведь хочешь строить бренд на фундаменте своей, а не чужой личности. Нормально изучать истории успеха других, но их нельзя просто взять и скопировать. Так и с моделью персонального бренда. Тест на определение модели даст четкий и структурированный план, с помощью которого ты будешь действовать уверенно и в своем ритме.'
        },
        { 
          question: 'Зачем тебе персональный бренд?',
          answer: 'Ты видишь людей с мощным персональным брендом и думаешь — с чего начать? Что в твоей личности станет стартовой точкой для запуска машины из роста аудитории, новых возможностей и развития твоего дела? Знаешь ли ты о себе достаточно, чтобы начать распространять значимые для тебя идеи, рассказывать о своих достижениях, вдохновлять других и привлекать новые возможности? Работа над собой — единственное выгодное вложение ресурсов. Определи свою модель персонального бренда и узнай удобный способ взаимодействия с аудиторией и проявления себя. '
        },
      ]
    },
    footer: { 
      terms: 'Условия\nиспользования',
      policy: 'Политика\nконфиденциальности',
      help: 'Связаться\nс нами'
    }
  },
};
