import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";
import classNames from "classnames/bind";
import Button from "components/Button";
import { ReactComponent as IconPlus } from "assets/icons/plus.svg";
import { ReactComponent as IconMinus } from "assets/icons/minus.svg";
const cx = classNames.bind(styles);

type QuestionType = {
  question: string;
  answer: string;
};

const Faq = () => {
  const [t] = useTranslation();
  const questions: QuestionType[] = t("pageHome.faq.questions", {
    returnObjects: true,
  });

  return (
    <section className={cx("Component")}>
      <div className={cx("container")}>
        {questions.map((data, i) => (
          <Question {...data} key={i} />
        ))}
      </div>
    </section>
  );
};

const Question = ({ question, answer }: QuestionType) => {
  const [t] = useTranslation();
  const [show, setShow] = useState(false);

  return (
    <div className={cx("Question")}>
      <div className={cx("QuestionHeader")}>
        <h3 className={cx("QuestionName")}>{question}</h3>

        <button
          onClick={() => setShow(!show)}
          className={cx("QuestionToggleBtn")}
        >
          {show ? <IconMinus /> : <IconPlus />}
        </button>
      </div>

      <div className={cx("QuestionAnswer", show && "show")}>
        <p>{answer}</p>

        <Button className={cx("QuestionBtn")}>{t("pageHome.faq.btn")}</Button>
      </div>
    </div>
  );
};

export default Faq;
