import React, { useState, Fragment } from "react";
import styles from "./index.module.scss";
import classNames from "classnames/bind";
import Logo from "assets/logo.svg";
import Sidebar from "../Sidebar";
import { useTranslation } from "react-i18next";
import Button from "components/Button";

const cx = classNames.bind(styles);

const Intro = () => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <section className={cx("Component")}>
        <div className={cx("container", "Container")}>
          <header className={cx("Header")}>
            <span className={cx("Logo")}>
              <img src={Logo} alt="Fly_academy" />
            </span>

            <Sidebar />
          </header>

          <div className={cx("Content")}>
            <h1 className={cx("Title")}>{t("pageHome.intro.message")}</h1>
            <h2 className={cx("Subtitle")}>
              {t("pageHome.intro.description")}
            </h2>
            <Button className={cx('Btn')}>{t("pageHome.intro.btn")}</Button>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Intro;
