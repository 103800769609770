import React, { Fragment, MouseEvent, useEffect, useState } from "react";
import styles from "./index.module.scss";
import classNames from "classnames/bind";
import Menu from "assets/icons/menu.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
const cx = classNames.bind(styles);

const Sidebar = () => {
  const [t] = useTranslation();
  const [showSidebar, setShowSidebar] = useState(false);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  useEffect(() => {
    document.body.style.overflow = showSidebar ? "hidden" : "";
  }, [showSidebar]);

  return (
    <Fragment>
      <button onClick={toggleSidebar} className={cx("BtnSidebarControl")}>
        <img src={Menu} alt="" />
      </button>
      <aside className={cx("Component", showSidebar && "open")}>
        <div className={cx("Content")}>
          <div className={cx("Row")}>
            <div className={cx("RowContent", "Logo")}>
              <img src={require("assets/logo.svg")} alt="Fly_academy" />
            </div>
            <div className={cx("RowContent", "BtnCloseWrapper")}>
              <button onClick={toggleSidebar} className={cx("BtnClose")}>
                <img src={require("assets/icons/close.svg")} alt="Close" />
              </button>
            </div>
          </div>
          <NavLink
            label={t("sidebar.nav.reviews")}
            target="participants"
            onClick={toggleSidebar}
          />
          <NavLink
            label={t("sidebar.nav.about")}
            target="about-test"
            onClick={toggleSidebar}
          />
          <NavLink
            label={t("sidebar.nav.models")}
            target="model"
            onClick={toggleSidebar}
          />
          <NavLink
            label={t("sidebar.nav.benefits")}
            target="benefits"
            className={cx('BeforeBtnStartRow')}
            onClick={toggleSidebar}
          />

          <div className={cx("Row")}>
            <div className={cx("RowContent", "BtnStartWrapper")}>
              <Button className={cx("BtnStart")}>
                {t("sidebar.startTest")}
              </Button>
            </div>
          </div>

          <div className={cx("Row")}>
            <div className={cx("RowContent")}>
              <Link className={cx("NavLink")} to="/">
                {t("sidebar.nav.blog")}
              </Link>
            </div>
            <div className={cx("RowContent")}>
              <a className={cx("SocialLink")} href="https://instagram.com/">
                <img
                  src={require("assets/icons/instagram.svg")}
                  alt="Instagram"
                />
              </a>
            </div>
            <div className={cx("RowContent")}>
              <a className={cx("SocialLink")} href="https://facebook.com/">
                <img
                  src={require("assets/icons/facebook.svg")}
                  alt="Facebook"
                />
              </a>
            </div>
           </div>
        </div>
      </aside>
    </Fragment>
  );
};

const NavLink = (props: { label: string; target: string; onClick(): void, className?: string }) => {
  const onClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const target = document.getElementById(props.target);

    props.onClick();

    window.scrollTo({
      top: target?.offsetTop ?? 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className={cx("Row", props.className)}>
      <div className={cx("RowContent")}>
        <Link className={cx("NavLink")} to="/" onClick={onClick}>
          {props.label}
        </Link>
      </div>
    </div>
  );
};

export default Sidebar;
