import React from "react";
import styles from "./index.module.scss";
import classNames from "classnames/bind";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
const cx = classNames.bind(styles);

const Propose = () => {
  const [t] = useTranslation();
  return (
    <section className={cx("Component")}>
      <div className={cx("container", "Container")}>
        <h2 className={cx("Title")}>{t("pageHome.propose.title")}</h2>

        <div className={cx("BtnWrapper")}>
          <Button className={cx("Btn")}>{t("pageHome.propose.btn")}</Button>
        </div>
        <p className={cx("Description")}>{t("pageHome.propose.description")}</p>

        <div className={cx("ImageBlock")}>
          <img className={cx('Image')} src={require("assets/images/window.svg")} alt="" />

          <div className={cx("Price")}>
            <img src={require("assets/images/price_bg.svg")} alt="" />
            <p className={cx("PriceValue", "old")}>$19.95</p>
            <p className={cx("PriceValue", "new")}>
              {t("pageHome.propose.free")}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Propose;
