import React from "react";
import styles from "./index.module.scss";
import classNames from "classnames/bind";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
const cx = classNames.bind(styles);

const Footer = () => {
  const [t] = useTranslation();

  const onClickToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <footer className={cx("Component")}>
      <div className={cx("Container","container")}>
        <div className={cx("Content")}>
          <div className={cx("Company")}>
            <div className={cx("Logo")}>
              <img src={require("assets/logo.svg")} alt="" />
            </div>
            <p className={cx("Copyright")}>© Fly_Academy 2017-2020</p>
          </div>
          <div className={cx("SocialLinks")}>
            <div>
              <a href="" target="_blank">
                <img src={require("assets/icons/instagram.svg")} />
              </a>
            </div>
            <div>
              <a href="" target="_blank">
                <img src={require("assets/icons/facebook.svg")} />
              </a>
            </div>
            <div className={cx("BtnTopBlock")}>
              <button onClick={onClickToTop} className={cx("BtnTop")}>
                <img src={require("assets/icons/arrow_sm-top.svg")} alt="" />
              </button>
            </div>
          </div>
          <div className={cx("Nav")}>
            <div>
              <Link to="/">{t("pageHome.footer.policy")}</Link>
            </div>
            <div>
              <Link to="/">{t("pageHome.footer.terms")}</Link>
            </div>
            <div>
              <Link to="/">{t("pageHome.footer.help")}</Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
