import React from "react";
import styles from "./index.module.scss";
import classNames from "classnames/bind";
import { useTranslation } from "react-i18next";
const cx = classNames.bind(styles);

const Benefits = () => {
  const [t] = useTranslation();
  const benefits: string[] = t("pageHome.benefits.list", {
    returnObjects: true,
  });

  return (
    <section id="benefits" className={cx("Component")}>
      <div className={cx("container")}>
        <h2 className={cx("Title")}>{t("pageHome.benefits.title")}</h2>

        <div className={cx("Content")}>
          <div className={cx("ImageBlock")}>
            <img src={require("assets/images/phone.svg")} alt="" />
          </div>

          <div className={cx("List")}>
            {benefits.map((text, index) => {
              return (
                <div className={cx("Item")}>
                  <div className={cx("ItemNumber")}>{1 + index}</div>
                  <p className={cx("ItemText")}>{text}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Benefits;
