import React from 'react'
import styles from './index.module.scss'
import classNames from 'classnames/bind'
const cx = classNames.bind(styles)

const Button = ({ className, ...props}:  JSX.IntrinsicElements['button']) => {
    return (
        <button className={cx('Component', className)}  {...props} />
    )
}

export default Button
