import React, { Fragment, ReactNode } from "react";
import styles from "./index.module.scss";
import classNames from "classnames/bind";
import { ReactComponent as IconArrowRight } from "assets/icons/arrow-right.svg";
const cx = classNames.bind(styles);

const Arrows = () => (
  <Fragment>
    <span className={cx("Icon")}>
      <IconArrowRight />
    </span>
    <span className={cx("Icon")}>
      <IconArrowRight />
    </span>
    <span className={cx("Icon")}>
      <IconArrowRight />
    </span>
  </Fragment>
);

const Ticker = ({ message }: { message: ReactNode }) => {
  const Text = () => <span>{message}</span>;

  return (
    <div className={cx("Ticker")}>
      <div className={cx("Line")}>
        <div className={cx("Message")}>
          <Arrows />
          <Text />
          <Arrows />
          <Text />
        </div>
        <div className={cx("Message")}>
          <Arrows />
          <Text />
          <Arrows />
          <Text />
        </div>
      </div>
      <div className={cx("Line")}>
        <div className={cx("Message")}>
          <Text />
          <Arrows />
          <Text />
          <Arrows />
        </div>
        <div className={cx("Message")}>
          <Text />
          <Arrows />
          <Text />
          <Arrows />
        </div>
      </div>

    </div>
  );
};

export default Ticker;
