import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import PageLanding from "pages/Landing";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact  component={PageLanding} />
          
      </Switch>
    </Router>
  );
}

export default App;
