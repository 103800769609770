import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import App from "./App";
import { ApolloProvider } from "@apollo/client";
import client from "api";
import 'styles/index.scss'
import 'locale'

if (process.env.NODE_ENV !== "development") {
  Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DNS });
}

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
