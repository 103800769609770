import React, { Fragment, ReactNode, useEffect, useRef, useState } from "react";
import { Swipeable } from "react-swipeable";
import styles from "./index.module.scss";
import classNames from "classnames/bind";
const cx = classNames.bind(styles);

type Props = {
  slides: ReactNode[];
  // it can be controlled component or not
  setCurrentSlide?: React.Dispatch<React.SetStateAction<number>>;
  currentSlide?: number;
};

const Slider = ({ slides, ...props }: Props) => {
  const itemsCount = slides.length;
  let [currentSlide, setCurrentSlide] = useState(0);

  if (props.setCurrentSlide && typeof props.currentSlide === "number") {
    currentSlide = props.currentSlide;
    setCurrentSlide = props.setCurrentSlide;
  }

  const sliderRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!sliderRef.current) return;
    let correctSlideNumber = currentSlide;

    if (correctSlideNumber >= itemsCount) {
      correctSlideNumber = 0;
    } else if (correctSlideNumber < 0) {
      correctSlideNumber = itemsCount - 1;
    }

    const slides = (sliderRef.current.childNodes ?? []) as NodeListOf<
      HTMLDivElement
    >;
    let translateX = 0;
    for (let i = 0; i < slides.length; i++) {
      if (i < correctSlideNumber) {
        translateX += slides[i].offsetWidth;
      }
    }
    setCurrentSlide(correctSlideNumber);
    sliderRef.current.style.transform = `translateX(-${translateX}px)`;
  }, [currentSlide]);

  const onClickNextSlide = () => {
    let nextSlide = currentSlide + 1;
    setCurrentSlide(nextSlide);
  };

  const onClickPrevSlide = () => {
    let nextSlide = currentSlide - 1;
    setCurrentSlide(nextSlide);
  };

  return (
    <Fragment>
      <Swipeable
        style={{ overflow: "hidden" }}
        trackMouse
        trackTouch
        onSwipedLeft={onClickNextSlide}
        onSwipedRight={onClickPrevSlide}
      >
        <div className={cx("Slider", "container")} ref={sliderRef}>
          {slides.map((slide, i) => {
            return (
              <div className={cx("SlideWrapper")}>
                <div className={cx("Slide")} key={i}>
                  {slide}
                </div>
              </div>
            );
          })}
        </div>
      </Swipeable>
      <div className={cx("SliderDots")}>
        {slides.map((_, index) => {
          return (
            <button
              onClick={() => setCurrentSlide(index)}
              type="button"
              className={cx("SliderBtnDot", index === currentSlide && "active")}
            />
          );
        })}
      </div>
    </Fragment>
  );
};

export default Slider;
