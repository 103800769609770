import React from "react";
import styles from "./index.module.scss";
import classNames from "classnames/bind";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
const cx = classNames.bind(styles);

const Chat = () => {
  const [t] = useTranslation();
  return (
    <section className={cx("Component")}>
      <div className={cx("container", "Container")}>
        <h2 className={cx("Title")}>{t("pageHome.chat.title")}</h2>

        <div className={cx("Messages")}>
          <div className={cx("MessageRow", "align-right")}>
            <p className={cx("Message", "bg_yellow")}>
              {t("pageHome.chat.messages.0")}{' '}
              <img src={require("assets/images/landing_message_1-1.svg")} />
              <img src={require("assets/images/landing_message_1-2.svg")} />
              <img src={require("assets/images/landing_message_1-3.svg")} />
            </p>
            <span className={cx("MessageTime")}>12:32</span>
          </div>
          <div className={cx("MessageRow", "align-left")}>
            <p className={cx("Message", "bg_purple")}>
              <img src={require("assets/images/landing_message_2.svg")} />
            </p>
            <span className={cx("MessageTime")}>12:34</span>
          </div>
          <div className={cx("MessageRow", "align-left")}>
            <p className={cx("Message", "bg_purple")}>
              {t("pageHome.chat.messages.2")}{ ' '}
              <img src={require("assets/images/landing_message_3.svg")} />
            </p>
            <span className={cx("MessageTime")}>12:34</span>
          </div>
          <div className={cx("MessageRow", "align-right")}>
            <p className={cx("Message", "bg_pink")}>
              {t("pageHome.chat.messages.3")}
            </p>
            <span className={cx("MessageTime")}>12:36</span>
          </div>
          <div className={cx("MessageRow", "align-left")}>
            <p className={cx("Message", "bg_purple")}>
              {t("pageHome.chat.messages.4")}
            </p>
            <span className={cx("MessageTime")}>12:40</span>
          </div>
          <div className={cx("MessageRow", "align-right")}>
            <p className={cx("Message", "bg_yellow")}>
              {t("pageHome.chat.messages.5")}{ " "}
              <img src={require("assets/images/landing_message_6-1.svg")} />
              <img src={require("assets/images/landing_message_6-2.svg")} />
              <img src={require("assets/images/landing_message_6-3.svg")} />
            </p>
            <span className={cx("MessageTime")}>12:44</span>
          </div>
          <div className={cx("MessageRow", "align-right", "allow-radius")}>
            <p className={cx("Message", "MessageTyping", "bg_yellow")}>
              <span className={cx('MessageTypingDot')} />
              <span className={cx('MessageTypingDot')} />
              <span className={cx('MessageTypingDot')} />
            </p>
          </div>
        </div>

        <Button className={cx("Btn")}>{t("pageHome.chat.btn")}</Button>
      </div>
    </section>
  );
};

export default Chat;
