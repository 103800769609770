import React from "react";
import TextSection from "./components/TextSection";
import Intro from "./Intro";
import styles from "./index.module.scss";
import classNames from "classnames/bind";
import Participants from "./Participants";
import { useTranslation } from "react-i18next";
import Ticker from "./components/Ticker";
import Chat from "./Chat";
import Model from "./Model";
import Propose from "./Propose";
import Benefits from "./Benefits";
import Faq from "./Faq";
import Footer from "./Footer";
const cx = classNames.bind(styles);

const PageLanding = () => {
  const [t] = useTranslation();
  return (
    <main className={cx("Component")}>
      <Intro />
      <Ticker message={t("pageHome.ticker.create")} />
      <TextSection
        text={t("pageHome.about.text", { returnObjects: true })}
        btnText={t("pageHome.about.btn")}
      />
      <Participants />
      <Chat />
      <Ticker message={t("pageHome.ticker.brand")} />
      <TextSection id='about-test' text={t("pageHome.brand.text", { returnObjects: true })} />
      <Model />
      <Propose />

      <Benefits />
      <Ticker message={t("pageHome.ticker.target")} />
      <Faq />
      <Footer />
    </main>
  );
};

export default PageLanding;
