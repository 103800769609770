import { ReactComponent as IconArrowLeft } from "assets/icons/arrow_sm-left.svg";
import { ReactComponent as IconArrowRight } from "assets/icons/arrow_sm-right.svg";
import classNames from "classnames/bind";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Slider from "../components/Slider";
import styles from "./index.module.scss";

const cx = classNames.bind(styles);

const participants = [
  <img src={require("assets/images/participant_1.png")} />,
  <img src={require("assets/images/participant_1.png")} />,
  <img src={require("assets/images/participant_1.png")} />,
  <img src={require("assets/images/participant_1.png")} />,
  <img src={require("assets/images/participant_1.png")} />,
  <img src={require("assets/images/participant_1.png")} />,
  <img src={require("assets/images/participant_1.png")} />,
];

const Participants = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [t] = useTranslation();

  const onClickNextSlide = () => {
    setCurrentSlide(currentSlide + 1);
  };

  const onClickPrevSlide = () => {
    setCurrentSlide(currentSlide - 1);
  };

  return (
    <section id="participants" className={cx("Component")}>
      <header className={cx("Header", "container")}>
        <h2 className={cx("Title")}>{t("pageHome.participants.title")}</h2>

        <div className={cx("SliderArrows")}>
          <button
            className={cx("SlideBtnArrowPrev")}
            type="button"
            onClick={onClickPrevSlide}
          >
            <IconArrowLeft />
          </button>
          <button
            className={cx("SlideBtnArrowNext")}
            type="button"
            onClick={onClickNextSlide}
          >
            <IconArrowRight />
          </button>
        </div>
      </header>

      <Slider
        slides={participants}
        currentSlide={currentSlide}
        setCurrentSlide={setCurrentSlide}
      />
    </section>
  );
};

export default Participants;
