import React, { Fragment } from "react";
import styles from "./index.module.scss";
import classNames from "classnames/bind";
import Button from "components/Button";
const cx = classNames.bind(styles);

type Props = {
  text: string[];
  btnText?: string;
} & JSX.IntrinsicElements['section']

const TextSection = ({ btnText, text, className = '', ...props}: Props) => {
  return (
    <Fragment>
      <section className={cx("Component", className)} {...props}>
        <div className={cx("Content", "container")}>
          <div className={cx("Description")}>
            {text.map((text, i) => (
              <p key={i}>{text}</p>
            ))}
          </div>

          {btnText && <Button className={cx('Btn')}>{btnText}</Button>}
        </div>
      </section>
    </Fragment>
  );
};

export default TextSection;
